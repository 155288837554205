import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosClient from "../../../hooks/useAxiosClient";
import {
  getPaymentHistory,
  updateCropData,
  updateProgressDetails,
  updateTradeDetails,
  updateTradeId,
} from "../../../reducers/tradeSlice";
import { TRootState } from "../../../store";
import {
  TCrop,
  TInvoiceNumber,
  TPaymentHistory,
  TTradeCompleteDetai,
  TTradeDocument,
  TTradeInvoices,
} from "../../../types";
import moment from "moment";
const useOrderView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { handleGetCall, handlePostCall } = useAxiosClient();
  const dispatch = useDispatch();
  const [tradeDetail, setTradeDetail] = useState<TTradeCompleteDetai | null>(
    null
  );
  const {
    traderId,
    progressStatus,
    tradeType,
    paymentHistoryObj,
    paymentUpdateStatus,
    tradeidVal,
  } = useSelector((state: TRootState) => ({
    traderId: state.auth.traderId,
    progressStatus: state.trades.progressStatus,
    tradeType: state.auth.tradeType,
    paymentHistoryObj: state.trades.paymentHistoryObj,
    paymentUpdateStatus: state.trades.paymentUpdateStatus,
    tradeidVal: state.trades.tradeId,
  }));
  // const acceptTradeRef: any = useRef(null);
  // var paymentId = paymentHistoryObj != null ? paymentHistoryObj?.id : 0;
  const [showStatusMessage, setshowStatusMessage] = useState("");
  const [showAcceptTradeModal, setShowAcceptTradeModal] = useState(false);
  const [documentVewer, setDocumentViewer] = useState<{
    open: boolean;
    url: string;
    title: string;
    docType: "image" | "pdf";
  }>({
    open: false,
    url: "",
    title: "",
    docType: "image",
  });
  const [acceptOtpNNumber, setAcceptOtpNumber] = useState<string>("");
  const openInFullScreen = (
    url: string,
    type: string,
    docType: "image" | "pdf"
  ) => {
    setDocumentViewer({ open: true, url: url, title: type, docType: docType });
  };
  const acceptTrade = async () => {
    if (acceptOtpNNumber === "") {
      toast.error("Please enter OTP");
      return;
    }
    if (acceptOtpNNumber.length !== 6) {
      toast.error("Please enter 6 digit OTP");
      return;
    }
    let postBody = {
      destOtp: acceptOtpNNumber.toString(),
      destPartyId: tradeDetail?.destPartyId,
      srcOtp: "",
      srcPartyId: tradeDetail?.srcPartyId,
      tradeId: tradeDetail?.tradeId,
      destName: tradeDetail?.destTradeName,
    };
    await handlePostCall<any>({
      URL: "mandi/trade-portal/trades/confirm",
      apiParams: postBody,
    })
      .then(({ data, status }) => {
        toast.success(status.message);
        navigate(`/orders`);
      })
      .catch((err: any) => {
        toast.error(err.response.data.status.message);
      });
    // acceptTradeRef.current.closeModal();
    setShowAcceptTradeModal(false);
  };
  const requestOtp = async () => {
    const toastid = toast.loading(
      "Otp has been sent to registered mobile number. Plase wait...",
      {
        type: "info",
      }
    );
    // toast.success("Otp has been sent to registered mobile number");
    let postBody = {
      destPartyId: tradeDetail?.destPartyId,
      srcPartyId: tradeDetail?.srcPartyId,
      tradeId: tradeDetail?.tradeId,
      srcName: tradeDetail?.srcTradeName,
      destName: tradeDetail?.destTradeName,
      date: moment(tradeDetail?.timeStamp).format("YYYY-MM-DD"),
    };
    try {
      await handlePostCall<any>({
        URL: "mandi/trade-portal/trades/otp/request",
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.update(toastid, {
            type: "success",
            render: status.description,
            isLoading: false,
            autoClose: 2000,
          });
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.message);
        });
    } catch (ex) {
      toast.update(toastid, {
        type: "error",
        render: "Failed to sent otp please try again",
        isLoading: false,
        autoClose: 2000,
      });
    }
  };
  const calculateAdvancePaid = (history: any) => {
    let sum = 0;
    if (history.length > 0 || tradeType == "DEST") {
      for (let item of history) {
        if (
          item.pmtType.toUpperCase() == "ADVANCE" &&
          item.inOutWard?.toUpperCase() == "INWARD" &&
          (item.pmtStatus == "ACCEPTED" || item.pmtStatus == "PENDING")
        ) {
          sum += item.amount;
        }
      }
    }
    return sum;
  };
  const calculateAdvancePaidSrc = (history: any) => {
    let sum = 0;
    if (history.length > 0 || tradeType == "SOURCE") {
      for (let item of history) {
        if (
          item.pmtType.toUpperCase() == "ADVANCE" &&
          item.inOutWard?.toUpperCase() == "OUTWARD" &&
          item.pmtStatus == "ACCEPTED"
        ) {
          sum += item.amount;
        }
      }
    }
    return sum;
  };
  const addDays = (date: any, days: any) => {
    if (days) {
      var dateVal = date ? new Date(date) : new Date();
      var result = "";
      dateVal.setDate(dateVal.getDate() + parseInt(days));
      result = moment(dateVal).format("DD-MMM-YYYY");
      return result;
    }
  };
  const getTradeDetail = () => {
    handleGetCall<TTradeCompleteDetai>(
      `/mandi/trade-portal/trades/traderId/${traderId}/tradeId/${params.tradeid}/type/${tradeType}`
    )
      .then(({ data }) => {
        let invoices: TTradeDocument[] = [];
        let recipts: TTradeDocument[] = [];
        let others: TTradeDocument[] = [];
        let arrivals: TTradeDocument[] = [];
        let patti: TTradeDocument[] = [];
        let payment: TTradeDocument[] = [];
        let arrivalsWeight: TTradeDocument[] = [];
        let cropsData: TCrop;
        let inwardHistory: TPaymentHistory[] = [];
        let outwardHistory: TPaymentHistory[] = [];
        let advReqImages: TTradeDocument[] = [];
        let invoiceTradeObj: TTradeInvoices = {};
        if (data.images) {
          if (data.images.length > 0) {
            for (let image of data.images) {
              let docType: "pdf" | "image" = image.url.includes(".pdf")
                ? "pdf"
                : "image";
              if (image.type === "tapal") {
                invoices.push({ ...image, docType: docType });
              } else if (image.type === "receipt") {
                recipts.push({ ...image, docType: docType });
              } else if (image.type === "other") {
                others.push({ ...image, docType: docType });
              } else if (image.type === "arrival") {
                arrivals.push({ ...image, docType: docType });
              } else if (image.type === "patti") {
                patti.push({ ...image, docType: docType });
              } else if (image.type === "payment" && paymentUpdateStatus) {
                payment.push({ ...image, docType: docType });
              } else if (image.type === "arrivalWight") {
                arrivalsWeight.push({ ...image, docType: docType });
              }
              // else if (image.type === "ADVANCE") {
              //   advReqImages.push({ ...image, docType: docType });
              // }
            }
          }
        }
        if (data.reqAdv != null) {
          if (data.reqAdv.images.length > 0) {
            for (let imag of data.reqAdv.images) {
              let docType: "pdf" | "image" = imag.url.includes(".pdf")
                ? "pdf"
                : "image";
              advReqImages.push({ ...imag, docType: docType });
            }
          }
        }
        let tradeCommentsVal = "";
        if (data.progress.length > 0) {
          for (let status of data.progress!) {
            if (status.progState == "PENDING") {
              tradeCommentsVal = status.comments;
            }
          }
        }
        let acceptTradeDat = "";
        if (data.progress.length > 0) {
          for (let status of data.progress!) {
            if (status.progState == "ACCEPTED") {
              acceptTradeDat = status.createdOn;
            }
          }
        }
        // if (data.tradeInv != null && data.tradeInv.length > 0) {
        //   for (let status of data.tradeInv) {
        //     if (status.invoiceType == "SRC_INV") {
        //       invoiceTradeObj.srcinvoiceId = status.invoiceId;
        //     }
        //     if (status.invoiceType == "DEST_INV") {
        //       invoiceTradeObj.destinvoiceId = status.invoiceId;
        //     }
        //     if (status.invoiceType == "SRC_PF") {
        //       invoiceTradeObj.srcPFId = status.invoiceId;
        //     }
        //     if (status.invoiceType == "DEST_PF") {
        //       invoiceTradeObj.destPFId = status.invoiceId;
        //     }
        //   }
        //   console.log(invoiceTradeObj, data.tradeInv, "obj");
        // }
        let selectedParty = {
          addrLine: data.destAddrLine,
          altMobile: data.destAltMobile,
          city: data.destCity,
          traderId: data.destPartyId,
          marketName: data.destMarketName,
          mobile: data.destMobile,
          name: data.destName,
          shopNum: data.destShopNum,
          tradeName: data.destTradeName,
          // type:  "DEST" | "SOURCE",
          relMgr: "",
          rmMobile: "",
          status: "",
        };
        if (data.pmtHistory.length > 0) {
          for (let item of data.pmtHistory) {
            if (
              item.inOutWard == "OUTWARD" &&
              (item.pmtStatus === "ACCEPTED" || item.pmtStatus === "PENDING")
            ) {
              outwardHistory.push(item);
            } else {
              inwardHistory.push(item);
            }
          }
        }
        let receiveable =
          data.tradeMode == "COMM" && Number(data?.pattiAmt) > 0
            ? Number(data?.pattiAmt) -
              (Number(data?.freightPaid) +
                Number(data?.comm) +
                Number(data?.lbrCharges) +
                (data.pmtHistory.length > 0
                  ? calculateAdvancePaidSrc(data?.pmtHistory)
                  : 0) +
                Number(data?.misc))
            : data.tradeMode == "ORDER" &&
              Number(data?.totalReceivable) > 0 &&
              Number(data?.invAmt) -
                (Number(data?.freightPaid) +
                  (Number(data?.srcDisc) || 0) +
                  (data.pmtHistory.length > 0
                    ? calculateAdvancePaidSrc(data?.pmtHistory)
                    : 0));
        let payable =
          data.tradeMode == "COMM" && Number(data?.totalPayable) > 0
            ? Number(data?.pattiAmt) -
              (Number(data?.freightPaid) +
                Number(data?.comm) +
                Number(data?.lbrCharges) +
                (data.pmtHistory.length > 0
                  ? calculateAdvancePaid(data?.pmtHistory)
                  : 0) +
                Number(data?.misc))
            : data.tradeMode == "ORDER" &&
              Number(data?.totalPayable) > 0 &&
              Number(data?.invAmt) -
                (Number(data?.freightPaid) +
                  (Number(data?.destDisc) || 0) +
                  (data.pmtHistory.length > 0
                    ? calculateAdvancePaid(data?.pmtHistory)
                    : 0));
        let finalPay =
          Number(payable) + Number(data?.destPf) - Number(data?.destPfDisc);
        let loss =
          data?.lineItems.length > 0
            ? data?.lineItems[0].destWeight
              ? Number(data?.lineItems[0].weight) -
                Number(data?.lineItems[0].destWeight)
              : 0
            : 0;
        let finalReci =
          Number(receiveable) -
          // (data.pmtHistory.length > 0
          //   ? calculateAdvancePaidSrc(data?.pmtHistory)
          //   : 0) -
          Number(data?.srcPf) +
          Number(data?.srcPfDisc);
        for (let item of data.lineItems) {
          cropsData = {
            baseName: item.baseName,
            cropId: item.cropId,
            cropName: item.cropName,
            imageUrl: item.imageUrl,
            type: item.type,
            units: item.units,
            varietyName: item.varietyName,
            qtyUnit: item.qtyUnit,
            rateType: item.rateType,
            qty: item.qty,
            weight: item.weight,
            freightAmt: item.freightAmt,
            status: 2,
            tradeId: item.tradeId,
            weightUnit: item.weightUnit,
            id: item.id,
            add_wastage: item.add_wastage,
            destWeight: item.destWeight,
            destRate: item.destRate,
            rate: item.rate,
          };
          dispatch(
            updateCropData({
              selectedParty: selectedParty,
              selectedCrop: cropsData,
            })
          );
        }
        const unit_price =
          tradeType == "DEST"
            ? (Number(data?.destPf) - Number(data?.destPfDisc)) / 1.18
            : (Number(data?.srcPf) - Number(data?.srcPfDisc)) / 1.18;
        setTradeDetail({
          ...data,
          invoices: invoices,
          receipts: recipts,
          otherDocumets: others,
          selectedTrader: selectedParty,
          lineItems: data.lineItems,
          arrivalDocs: arrivals,
          pattiDocs: patti,
          paymentDocs: payment,
          freightAmt: data.freightAmt,
          invAmt: data.invAmt,
          tradeMode: data.tradeMode,
          freightPaid: data.freightPaid,
          date: data.date,
          pmtHistory: tradeType == "DEST" ? inwardHistory : outwardHistory,
          arrivalWeightbridgeDocs: arrivalsWeight,
          tradecomments: tradeCommentsVal,
          totalReceivable: receiveable || 0,
          moistureLoss: loss,
          totalPayable: payable || 0,
          destDis: data.destPfDisc || 0,
          srcDis: data.srcPfDisc || 0,
          finalPayable: finalPay,
          advancePaid: calculateAdvancePaid(data.pmtHistory) || 0,
          advanceRcvd: calculateAdvancePaidSrc(data.pmtHistory) || 0,
          commodityCost: data?.cmdityCost || 0,
          reqAdv: data?.reqAdv,
          reqAdvanceDocuments: advReqImages,
          advLimit: data.advLimit || 0,
          destEsign: data.destEsign,
          srcEsign: data.srcEsign,
          acceptTradeDate: acceptTradeDat,
          finalReceivable: finalReci,
          unitPrice: unit_price.toFixed(2),
          totalAmount: parseFloat(unit_price.toFixed(2)),
          gstAmount: parseFloat(unit_price.toFixed(2)) * 0.18,
          payableAfterGst:
            tradeType == "DEST"
              ? Number(data?.destPf) - Number(data?.destPfDisc)
              : Number(data?.srcPf) - Number(data?.srcPfDisc),
          // tradeInvoiceObj: invoiceTradeObj,
          destTax: data.destTax,
          srcTax: data.srcTax,
          srcInvId: data.srcInvId,
          destInvId: data.destInvId,
          srcPfId: data.srcPfId,
          destPfId: data.destPfId,
          srcTradeStatus: data.srcTradeStatus,
          tradeStatus: data.destTradeStatus,
          dueDate:
            addDays(
              data?.arlDate,
              tradeType == "DEST"
                ? data?.destPmtTerm || ""
                : data?.srcPmtTerm || ""
            ) || "-",
        });
        dispatch(
          updateTradeDetails({
            tradeDetails: {
              ...data,
              invoices: invoices,
              receipts: recipts,
              otherDocumets: others,
              selectedTrader: selectedParty,
              lineItems: data.lineItems,
              progress: data.progress,
              arrivalDocs: arrivals,
              pattiDocs: patti,
              paymentDocs: payment,
              freightAmt: data.freightAmt,
              invAmt: data.invAmt,
              tradeMode: data.tradeMode,
              freightPaid: data.freightPaid,
              date: data.date,
              pmtHistory: tradeType == "DEST" ? inwardHistory : outwardHistory,
              tradecomments: tradeCommentsVal,
              totalReceivable: receiveable || 0,
              moistureLoss: loss,
              netWeight:
                (Number(data?.destWeight) > 0 &&
                  Number(data?.destWeight) - Number(data?.destWastage)) ||
                0,
              destDis: data.destPfDisc || 0,
              srcDis: data.srcPfDisc || 0,
              totalPayable: payable || 0,
              finalPayable: finalPay,
              advancePaid: calculateAdvancePaid(data.pmtHistory) || 0,
              advanceRcvd: calculateAdvancePaidSrc(data.pmtHistory) || 0,
              commodityCost: data?.cmdityCost || 0,
              reqAdv: data?.reqAdv,
              reqAdvanceDocuments: advReqImages,
              advLimit: data.advLimit || 0,
              destWeight: data.lineItems[0].destWeight || 0,
              destWastage: data.lineItems[0].add_wastage || 0,
              destEsign: data.destEsign,
              srcEsign: data.srcEsign,
              acceptTradeDate: acceptTradeDat,
              finalReceivable: finalReci,
              unitPrice: Math.ceil(unit_price),
              totalAmount: Math.ceil(unit_price),
              gstAmount: unit_price * 0.18,
              payableAfterGst: Number(data?.destPf) - Number(data?.destPfDisc),
              // tradeInvoiceObj: invoiceTradeObj,
              destTax: data.destTax,
              srcTax: data.srcTax,
              srcInvId: data.srcInvId,
              destInvId: data.destInvId,
              srcPfId: data.srcPfId,
              destPfId: data.destPfId,
              srcTradeStatus: data.srcTradeStatus,
              tradeStatus: data.destTradeStatus,
              dueDate:
                addDays(
                  data?.arlDate,
                  tradeType == "DEST"
                    ? data?.destPmtTerm || ""
                    : data?.srcPmtTerm || ""
                ) || "-",
            },
          })
        );
      })
      .catch((err: any) => {
        toast.error(err.response.data.status.message);
        if (err.response.data.status.message == "INVALID_INPUT") {
          setshowStatusMessage(
            "You are an unauthorized user to view the trade details."
          );
        }
      });
  };
  // useEffect(() => {
  //   if (tradeDetail != null && tradeDetail?.tradeStatus == "CLOSED") {
  //     console.log(
  //       (tradeDetail?.destPf - tradeDetail?.destDis) / 1.18,
  //       "(tradeDetail?.destPf - tradeDetail?.destDis) / 1.18"
  //     );
  //     setTradeDetail({
  //       ...tradeDetail,
  //       unitPrice: parseFloat(
  //         ((tradeDetail?.destPf - tradeDetail?.destDis) / 1.18).toFixed(2)
  //       ),
  //     });
  //     updateTradeDetails({
  //       tradeDetails: {
  //         ...tradeDetail,
  //         unitPrice: parseFloat(
  //           ((tradeDetail?.destPf - tradeDetail?.destDis) / 1.18).toFixed(2)
  //         ),
  //       },
  //     });
  //   }
  // }, []);
  const [otherDocuments, setOtherDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const onUploadReceiptImage = async (file: any) => {
    let fileType = "";
    if (file.type === "application/pdf") {
      fileType = "pdf";
    } else if (file.type.includes("image/")) {
      fileType = "image";
    }
    let formdata = new FormData();
    formdata.append("traderId", traderId.toString());
    if (fileType === "pdf") {
      formdata.append("file", file, file.name.replace(" ", "-"));
      let imageUploadResponse = await handlePostCall<string[]>({
        URL: "/mandi/files/trade-portal/imgs/upload",
        apiParams: formdata,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      });
      let url = imageUploadResponse.data[0];

      setOtherDocuments([...otherDocuments, { docType: "pdf", url: url }]);
    } else {
      new Compressor(file, {
        quality: 0.8,
        success: async (compressedResult) => {
          formdata.append(
            "file",
            compressedResult,
            file.name.replace(" ", "-")
          );
          let imageUploadResponse = await handlePostCall<string[]>({
            URL: "/mandi/files/trade-portal/imgs/upload",
            apiParams: formdata,
            config: {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          });
          let url = imageUploadResponse.data[0];

          setOtherDocuments([
            ...otherDocuments,
            { docType: "image", url: url },
          ]);
        },
      });
    }
  };
  const onRemoveReceiptImage = (index: number, isExist: boolean = false) => {
    if (isExist === true && tradeDetail !== null) {
      let invoices = [...tradeDetail.otherDocumets];
      let i = invoices.findIndex((img) => img.id === index);
      invoices[i] = { ...invoices[i], status: 0 };
      setTradeDetail({ ...tradeDetail, otherDocumets: invoices });
    } else {
      let newtapalInvoice = [...otherDocuments];
      newtapalInvoice.splice(index, 1);
      setOtherDocuments(newtapalInvoice);
    }
  };
  const onClickPaymentId = () => {
    var id = localStorage.getItem("tradeIdLocal");
    handleGetCall<TPaymentHistory>(
      `/mandi/trade-portal/trades/payments/traderId/${traderId}/tradeId/${id}/pmtId/${paymentHistoryObj?.id}`
    )
      .then(({ data }) => {
        dispatch(
          getPaymentHistory({
            paymentHistoryObj: data,
          })
        );
      })
      .catch((err: any) => {
        toast.error(err.response.data.status.message);
      });
  };
  return {
    tradeDetail,
    documentVewer,
    setDocumentViewer,
    openInFullScreen,
    acceptTrade,
    setAcceptOtpNumber,
    getTradeDetail,
    requestOtp,
    setShowAcceptTradeModal,
    showAcceptTradeModal,
    showStatusMessage,
    onUploadReceiptImage,
    otherDocuments,
    onRemoveReceiptImage,

    setTradeDetail,
    onClickPaymentId,
  };
};
export default useOrderView;
