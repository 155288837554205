import { FC } from "react";
import defultProfilePic from "../../assets/images/single_bill.svg";
import { getMaskedMobileNumber } from "../../helpers/get-currency-number";
import { TPartnerInfo } from "../../types";

const UserDetails: FC<TPartnerInfo> = ({
  profilePic,
  name,
  partnerMobile,
  partnerId,
  seqId,
  partyName,
}) => {
  return (
    <div className="flex items-center gap-x-3">
      <div>
        <img
          src={profilePic || defultProfilePic}
          alt="user_profile_pic"
          className="w-8 h-8 br-100"
          style={{ maxWidth: "initial" }}
        />
      </div>
      <div className="flex flex-col">
        <span className="text-sm font-medium">{`${name}`}</span>
        <span className="text-xs ">{` Trader - ${partnerId} | ${getMaskedMobileNumber(
          partnerMobile
        )}`}</span>
        <span className="text-xs">{`${partyName}`}</span>
        {/* <span className="text-xs ">{`trader_seq :  ${seqId}`}</span> */}
      </div>
    </div>
  );
};
export default UserDetails;
